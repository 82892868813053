import 'ol/ol.css';
import {Map, View, Feature} from 'ol';
import OSM from 'ol/source/OSM';
import {fromLonLat} from 'ol/proj';
import Point from 'ol/geom/Point';
import {Icon, Style} from 'ol/style';
import VectorSource from 'ol/source/Vector';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import pin from '../assets/pin.svg';

const marker = new Feature({
    geometry: new Point(fromLonLat([24.146933, 65.846742]))
})

marker.setStyle(new Style({
        image: new Icon({
            color: '#8959A8',
            crossOrigin: 'anonymous',
            imgSize: [30, 30],
            src: pin
        })
    })
);

var vectorSource = new VectorSource({
    features: [marker]
  });
  
  var vectorLayer = new VectorLayer({
    source: vectorSource
  });
  
  var rasterLayer = new TileLayer({
    source: new OSM()
  });
  
  var map = new Map({
    layers: [rasterLayer, vectorLayer],
    target: document.getElementById('map'),
    view: new View({
      center: fromLonLat([24.146933, 65.846742]),
      zoom: 18
    })
  });
